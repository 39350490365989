
const PathList = {
    Login: "/",
    Dashboard:"/dashboard",
    UserManagement:"/user",
    CategoryManagement:"/category",
    BusinessManagement:"/bussiness",
    BusinessDetails: "/business/businessdetails",
    ContentManagement:"/content",
    ContectUsFormManagement:"/contactus",
    ReportFeedManagement:"/reportfeed",
    ReportUserList:"/reportfeed/reportedfeeddetails",
    MediaDetails:"/reportfeed/mediadetails",
    ReportedJokesDetails:"/reportedfeed/reportedjokesdetails"
}

export default PathList
