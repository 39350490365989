import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import ReportedFeed from "../ReportFeedManagement/ReportedFeed";
import ReportedJokes from "../ReportFeedManagement/ReportedJokes";
import Loader from "../../Components/Loader";

const ReportFeedManagement = () => {
  const pageSize = 30;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [type, setType] = useState(1);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "16px 0px 0px 0px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setType(newValue === 0 ? 1 : newValue === 1 && 2);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(ApiList.REPORTED_FEED, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          feed_type: type,
        },
      });
      if (response && response?.data?.s === 1) {
        const Rowdata1 = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * 30,
          ...data,
        }));
        setData(Rowdata1);
        setCount(response?.data?.c);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [type, page]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            p: 2,
            position: "sticky",
            top: "0px",
            left: "0px",
            bgcolor: "white",
            zIndex: "2",
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "24px", xl: "35px" }, fontWeight: "600" }}
          >
            Reported Content
          </Typography>
        </Box>
        {isLoading ? (
          <>
            <Box
              sx={{
                height: "calc(100vh - 68px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          </>
        ) : (
          <Box sx={{ py: 2 }}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  padding: "10px 0px 0px 0px",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="content management tabs"
                >
                  <Tab
                    label="Feeds"
                    {...a11yProps(0)}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  />
                  <Tab
                    label="Jokes"
                    {...a11yProps(1)}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  />
                </Tabs>
                <TabPanel value={value} index={0} feed_type={1}>
                  <ReportedFeed
                    data={data}
                    setData={setData}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    count={count}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} feed_type={2}>
                  <ReportedJokes
                    data={data}
                    setData={setData}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    count={count}
                  />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReportFeedManagement;
