import React, { useEffect, useState } from "react";
import useDebounce from "../../Components/useDebounce";
import TableContainer from "../../Components/TableContainer";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import TopTitle from "../../Components/TopTitle";
import Loader from "../../Components/Loader";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import DUMMY_USER from "../../Assets/loginimages/logo.png";
const ContectUsFormManagement = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contectUsList, setContectUsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const handlePageChange = (newValue) => {
    setPage(newValue);
  };
  const getContactUsList = async () => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_CONTACT_US, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        },
      });
      if (res && res?.data?.s === 1) {
        const formattedUsers = res?.data?.r?.map((user, index) => ({
          rowid: index + 1 + page * pageSize,
          ...user,
        }));
        setContectUsList(formattedUsers);
        setCount(res?.data?.c);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getContactUsList();
  }, []);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_img",
      headerName: "Profile Photo",
      width: 150,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const userid = params?.row?.user_id;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              style={{
                height: "50px",
                width: "50px",
                padding: "5px",
                borderRadius: "12px",
              }}
              src={
                userid === null
                  ? DUMMY_USER
                  : params?.row?.user_details?.profile_img === null
                  ? DUMMY_USER
                  : `https://api.superkidz.online${params?.row?.user_details?.profile_img}`
              }
            />
          </Box>
        );
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth:200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const userid = params?.row?.user_id;
        return (
          <Typography>
            {userid === null
              ? params?.row?.full_name
              : params?.row?.user_details?.full_name}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth:250,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const userid = params?.row?.user_id;
        return (
          <Typography>
            {userid === null
              ? params?.row?.email
              : params?.row?.user_details?.email}
          </Typography>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth:250,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const subtitle = params?.row?.subject || "--";
        const truncatedSubtitle =
          subtitle.length > 30 ? `${subtitle.substring(0, 30)}...` : subtitle;

        return (
          <Tooltip title={subtitle}>
            <span>{truncatedSubtitle}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth:350,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const subtitle = params?.row?.description || "--";
        const truncatedSubtitle =
          subtitle.length > 45 ? `${subtitle.substring(0, 45)}...` : subtitle;

        return (
          <Tooltip title={subtitle}>
            <span>{truncatedSubtitle}</span>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        height: "100vh",
      }}
    >
      <TopTitle title={"Contact Us"} />
      <Box
        sx={{
          padding: "24px 0px 24px 0px",
        }}
      >
        <Box
          sx={{
            mt: 3,
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <TableContainer
              sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
              rows={contectUsList}
              columns={columns}
              loading={isLoading}
              pageSize={pageSize}
              hideFooter={true}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContectUsFormManagement;
