import PathList  from "../../Common/PathList";
import BusinessA from "../../Assets/sidebarActiveicons/BusinessA.png"
import CategoryA from "../../Assets/sidebarActiveicons/CategoryA.png"
import ContentA from "../../Assets/sidebarActiveicons/ContentA.png"
import DashboardA from "../../Assets/sidebarActiveicons/DashboardA.png"
import UsersA from "../../Assets/sidebarActiveicons/UsersA.png"
import ContactA from "../../Assets/sidebarActiveicons/ContactA.png"
import Business from "../../Assets/SidebarInactiveicons/Business.png"
import Category from "../../Assets/SidebarInactiveicons/Category.png"
import Content from "../../Assets/SidebarInactiveicons/Content.png"
import Dashboard from "../../Assets/SidebarInactiveicons/Dashboard.png"
import Users from "../../Assets/SidebarInactiveicons/Users.png"
import Contact from "../../Assets/SidebarInactiveicons/Contact.png"
import Report from "../../Assets/SidebarInactiveicons/Report.png"
import ReportA from "../../Assets/sidebarActiveicons/ReportA.png"
export const NavMenuList = [
  {
    title: "Dashboard",
    path: PathList.Dashboard,
    active:DashboardA,
    icon: Dashboard,
  },
  {
    title: "User Management",
    path: PathList.UserManagement,
    active:UsersA,
    icon: Users,
  },
  {
    title: "Category Management",
    path: PathList.CategoryManagement,
    active:CategoryA,
    icon: Category,
  },
  {
    title: "Business Management",
    path: PathList.BusinessManagement,
    active:BusinessA,
    icon: Business,
  },
  {
    title: "Content Management",
    path: PathList.ContentManagement,
    active:ContentA,
    icon: Content,
  },
  {
    title: "Contact Us",
    path: PathList.ContectUsFormManagement,
    active:ContactA,
    icon: Contact,
  },
  {
    title: "Reported Content",
    path: PathList.ReportFeedManagement,
    active:ReportA,
    icon: Report,
  },
  
];
