import React, { useEffect, useState } from "react";
import TopTitle from "../../Components/TopTitle";
import { Box, Grid, TextField, Typography } from "@mui/material";
import TableContainer from "../../Components/TableContainer";
import CustomAction from "../../Components/CustomAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../Components/CustomButton";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const CategoryManagement = () => {
  const pageSize = 10;
  const [isFeedLoading, setFeedIsLoading] = useState(true);
  const [isBusinessLoading, setBusinessIsLoading] = useState(false);
  const [feedCategoryList, setFeedCategoryList] = useState([]);
  const [businessCategoryList, setBusinessCategoryList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editFeedID, setEditFeedId] = useState(null);
  const [editBusinessID, setEditBusinessFeedId] = useState(null);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "category_name",
      headerName: "Category Name",
      flex: 1,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <CustomAction
          showDelete={true}
          showEdit={true}
          isEdit={() => handleEditfeedCategory(params?.row)}
          isDelete={() => handleDeleteFeedCategory(params?.row?.id)}
        />
      ),
    },
  ];
  const handleDeleteFeedCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Feed Category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.UPDATE_FEED_CATEGORY,
            params
          );
          if (response?.data?.s === 1) {
            toast.success("user Deleted Sucessfully !.");
            getFeedCategoryList();
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete user. Please try again.");
        }
      }
    });
  };
  const handleDeleteBusinessCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Business Category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("status", 0);
        try {
          const response = await AxiosInstance.post(
            ApiList.UPDATE_BUSINESS_CATEGORY,
            formData
          );
          if (response?.data?.s === 1) {
            toast.success("Business Deleted Sucessfully !.");
            getBusinessCategoryList();
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete user. Please try again.");
        }
      }
    });
  };
  const columns2 = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "category_name",
      headerName: "Category Name",
      flex: 1,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <CustomAction
          showDelete={true}
          showEdit={true}
          isEdit={() => handleBusinessEditCategory(params?.row)}
          isDelete={() => handleDeleteBusinessCategory(params?.row?.id)}
        />
      ),
    },
  ];
  const handleEditfeedCategory = (elm) => {
    if (elm) {
      feedformik.setFieldValue("feedCategoryName", elm?.category_name);
      setTypeCheck("Edit");
      setEditFeedId(elm?.id);
    }
  };
  const handleBusinessEditCategory = (elm) => {
    if (elm) {
      businessformik.setFieldValue("categoryName", elm?.category_name);
      setTypeCheck("Edit");
      setEditBusinessFeedId(elm?.id);
    }
  };
  const getFeedCategoryList = async () => {
    try {
      setFeedIsLoading(true);
      const fetchFeed = await AxiosInstance.get(ApiList.GET_FEED_CATEGORY);
      if (fetchFeed?.data?.s) {
        const formattedUsers = fetchFeed?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
        }));
        setFeedCategoryList(formattedUsers);
      }
      setFeedIsLoading(false);
    } catch (error) {
      console.log(error);
      setFeedIsLoading(false);
    }
  };
  const getBusinessCategoryList = async () => {
    try {
      setBusinessIsLoading(true);
      const fetchFeed = await AxiosInstance.get(ApiList.GET_BUSINESS_CATEGORY);
      if (fetchFeed?.data?.s) {
        const formattedUsers = fetchFeed?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
        }));
        setBusinessCategoryList(formattedUsers);
      }
      setBusinessIsLoading(false);
    } catch (error) {
      console.log(error);
      setBusinessIsLoading(false);
    }
  };
  useEffect(() => {
    getFeedCategoryList();
    getBusinessCategoryList();
  }, []);
  const feedformik = useFormik({
    initialValues: {
      feedCategoryName: "",
    },
    validationSchema: Yup.object().shape({
      feedCategoryName: Yup.string().required("Category name is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFeedIsLoading(true);
      try {
        const formData = new FormData();
        if (typeCheck === "Edit") {
          formData.append("id", editFeedID);
        }
        formData.append("category_name", values.feedCategoryName);
        const api_endpoint =
          typeCheck === "Edit"
            ? ApiList.UPDATE_FEED_CATEGORY
            : ApiList.ADD_FEED_CATEGORY;
        const res = await AxiosInstance.post(api_endpoint, formData);
        if (res?.data?.s) {
          resetForm();
          getFeedCategoryList();
          setFeedIsLoading(false);
          toast.success(
            `Feed category ${
              typeCheck === "Edit" ? "updated" : "added"
            } successfully`
          );
        }
        setFeedIsLoading(false);
      } catch (error) {
        setFeedIsLoading(false);
        console.log(error);
      }
    },
  });
  const businessformik = useFormik({
    initialValues: {
      categoryName: "",
    },
    validationSchema: Yup.object().shape({
      categoryName: Yup.string().required("Category name is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusinessIsLoading(true);
      try {
        const formData = new FormData();
        if (typeCheck === "Edit") {
          formData.append("id", editBusinessID);
        }
        formData.append("category_name", values.categoryName);
        const api_endpoint =
          typeCheck === "Edit"
            ? ApiList.UPDATE_BUSINESS_CATEGORY
            : ApiList.ADD_BUSINESS_CATEGORY;
        const res = await AxiosInstance.post(api_endpoint, formData);
        if (res?.data?.s) {
          resetForm();
          getBusinessCategoryList();
          setBusinessIsLoading(false);
          toast.success(
            `Business category ${
              typeCheck === "Edit" ? "updated" : "added"
            } successfully`
          );
        }
        setBusinessIsLoading(false);
      } catch (error) {
        setBusinessIsLoading(false);
        console.log(error);
      }
    },
  });
  return (
    <Box
      sx={{
        width: "100%",
        py: 2,
        px: 2,
      }}
    >
      <TopTitle title={"Category Management"} />
      <Box
        sx={{
          py: 2.4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "50px",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "30px",
              alignItems: "start",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ fontWeight: "500", mb: 1 }}>
                Feed Category
              </Typography>
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline ": {
                    outline: "none",
                    border: "none",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "15px",
                    border: "1px solid #40C4FF",
                    background: "#40C4FF1A",
                    height: "55px",
                    fontSize: "16px",
                  },
                }}
                type={"text"}
                placeholder={"Please Enter Category"}
                name={"feedCategoryName"}
                value={feedformik.values.feedCategoryName}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  feedformik.handleChange(e);
                }}
                onBlur={feedformik.handleBlur}
                onKeyUp={feedformik.handleBlur}
              />
              {feedformik.touched.feedCategoryName &&
              feedformik.errors.feedCategoryName ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {feedformik.errors.feedCategoryName}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "25px",
                marginTop: "32px",
              }}
            >
              <CustomButton
                title={"Add"}
                sx={{
                  width: "130px",
                }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  feedformik.handleSubmit();
                  return false;
                }}
                loading={isFeedLoading}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "30px",
              alignItems: "start",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ fontWeight: "500", mb: 1 }}>
                Business Category
              </Typography>
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline ": {
                    outline: "none",
                    border: "none",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "15px",
                    border: "1px solid #40C4FF",
                    background: "#40C4FF1A",
                    height: "55px",
                    fontSize: "16px",
                  },
                }}
                type={"text"}
                placeholder={"Please Enter Category"}
                name={"categoryName"}
                value={businessformik.values.categoryName}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  businessformik.handleChange(e);
                }}
                onBlur={businessformik.handleBlur}
                onKeyUp={businessformik.handleBlur}
              />
              {businessformik.touched.categoryName &&
              businessformik.errors.categoryName ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {businessformik.errors.categoryName}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "25px",
                marginTop: "32px",
              }}
            >
              <CustomButton
                title={"Add"}
                sx={{
                  width: "130px",
                }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  businessformik.handleSubmit();
                  return false;
                }}
                loading={isBusinessLoading}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "30px",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              mt: 3,
              width: "100%",
            }}
          >
            <TableContainer
              sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
              rows={feedCategoryList}
              columns={columns}
              pageSize={pageSize}
              loading={isFeedLoading}
              hideFooter={true}
              pagination={false}
            />
          </Box>
          <Box
            sx={{
              mt: 3,
              width: "100%",
            }}
          >
            <TableContainer
              sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
              pagination={false}
              rows={businessCategoryList}
              columns={columns2}
              pageSize={pageSize}
              loading={isBusinessLoading}
              hideFooter={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryManagement;
