import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopTitle from "../../Components/TopTitle";
import TableContainer from "../../Components/TableContainer";
import DUMMY_USER from "../../Assets/loginimages/logo.png";
import { toast } from "react-toastify";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import moment from "moment";
import CustomSearchBox from "../../Components/CustomSearch";
import useDebounce from "../../Components/useDebounce";
import CustomAction from "../../Components/CustomAction";
import Loader from "../../Components/Loader";
import Swal from "sweetalert2";
import CustomDropDown from "../../Components/CustomDropDown";
const UserManagement = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchUser, setSearchUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const debouncedSearch = useDebounce(searchUser, 500);
  const handlePageChange = (newValue) => {
    setPage(newValue);
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          user_id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(ApiList.BLOCK_USER, params);
          if (response?.data?.s === 1) {
            setUserList((prevData) =>
              prevData.filter((feed) => feed.id !== id)
            );
            toast.success("user Deleted Sucessfully !.");
            getUserList();
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete user. Please try again.");
        }
      }
    });
  };
  const handleBlockUnBlock = async (userId, isStatus) => {
    const newStatus = isStatus === 1 ? -1 : 1;
    Swal.fire({
      title: "Are you sure?",
      text:
        newStatus === -1
          ? "You want to block this user"
          : "You want to unblock this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("user_id", userId);
          formData.append("status", newStatus);
          const res = await AxiosInstance.post(ApiList.BLOCK_USER, formData);
          if (res?.data?.s) {
            toast.success(
              `User ${
                newStatus === -1 ? "blocked" : "unblocked"
              } Sucessfully !.`
            );
            getUserList();
          }
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_img",
      headerName: "Profile Photo",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={params?.row?.profile_img ? `https://api.superkidz.online${params?.row?.profile_img}` :  DUMMY_USER}
            alt="Profile"
            style={{ width: "45px", height: "45px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      minWidth: 150,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "premium",
      headerName: "Premium",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            bgcolor: "#3BC6F1",
            borderRadius: "12px",
          }}
        >
          <Typography
            sx={{ px: 3, py: 1, fontSize: "16px", fontWeight: "500" }}
          >
            Premium
          </Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const { status, id } = params?.row;
        console.log("id", id);

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {}
            <CustomAction
              showBlock={status === 1}
              showUnBlock={status === -1}
              showDelete={true}
              isBlock={handleBlockUnBlock}
              isUnBlock={handleBlockUnBlock}
              isDelete={() => handleDelete(params?.row?.id)}
              userId={id}
              isStatus={status}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getUserList();
  }, [debouncedSearch, page, selectedCategory]);
  const getUserList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_USER_LIST, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          search: debouncedSearch,
          status: selectedCategory,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1 + page * pageSize,
          ...user,
          dob: moment(user?.dob).format("DD/MM/YYYY"),
        }));
        setUserList(formattedUsers);
        setCount(response?.data?.c);
      } else {
        setUserList([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  const menuList = [
    {
      id: null,
      name: "All",
    },
    {
      id: -1,
      name: "Blocked",
    },
    {
      id: 1,
      name: "Unblocked",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        py: 2,
        px: 2,
        height: "100vh",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <TopTitle title={"User Management"} />
      </Box>
      <Box
        sx={{
          py: 2.4,
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={8} md={6}>
            <CustomSearchBox
              placeholder="Search User"
              value={searchUser}
              onChange={(e) => {
                setSearchUser(e.target.value);
                setPage(0);
              }}
              onClear={() => setSearchUser("")}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <CustomDropDown
              menuList={menuList}
              onChange={(e) => setSelectedCategory(e?.target?.value)}
              value={selectedCategory}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
          }}
        >
 
            <TableContainer
              sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
              rows={userList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              hideFooter={true}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
      
        </Box>
      </Box>
    </Box>
  );
};

export default UserManagement;
