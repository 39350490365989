export const ApiList = {
    //AUTH
    LOGIN:"/auth/login",
    FORGOT_PASSWORD:"/auth/forgot-password",
    GET_USER_LIST:"/user/get-all",
    REPORTED_FEED:"/feed/reported-feed",
    REPORTED_FEED_DETAILS:"/feed/reported-feed-details",
    DELETE_FEED:"/feed/remove",
    BLOCK_USER:"/user/account-status",
    GET_BUSINESS_CATEGORY:"/business/get-category",
    ADD_BUSINESS_CATEGORY:"/business/add-category",
    UPDATE_BUSINESS_CATEGORY:"/business/update-category",
    GET_FEED_CATEGORY:"/feed/get-category",
    ADD_FEED_CATEGORY:"/feed/add-category",
    UPDATE_FEED_CATEGORY:"/feed/update-category",
    GET_BUSINESS_LIST:"/business/get-all",
    UPDATE_BUSINESS:"/business/update",
    UPADTE_TERMS_CONDITIONS:"/content/update-terms-condition",
    GET_TERMS_CONDITIONS:"/content/get-terms-condition",
    UPDATE_PRIVACY_POLICY:"/content/update-privacy-policy",
    GET_PRIVACY_POLICY:"/content/get-privacy-policy",
    GET_ABOUT_US:"/content/get-aboutus",
    UPDATE_ABOUT_US:"/content/update-aboutus",
    GET_CONTACT_US:"/contact/get-all",
    GET_ADMIN_DASHBOARD:"/admin/analytics",
}