import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";

const CustomDropDown = (props) => {
  const { value, onChange, NoData, menuList, placeholder,title, sx = {}, titlesx = {} } = props;
  return (
    <>
      <Box sx={{width:"100%"}}>
        <Typography sx={{...titlesx}}>{title}</Typography>
        <Select
          sx={{
            width: "100%",
            height: "50px",
            border: "1px solid #135779CC",
            background: "linear-gradient(to left, #BAE6FD80, #F0F9FF80)",
            borderRadius: "5px",
            "&:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "&:hover": {
              outline: "none",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ...sx,
          }}
          value={value}
          onChange={onChange}
          displayEmpty
        >
          {menuList ? (
            menuList.map((elem, index) => (
              <MenuItem key={index} value={elem.id}>
                {elem.name || elem.category_name}
              </MenuItem>
            ))
          ) : (
            <Typography>{NoData}</Typography>
          )}
        </Select>
      </Box>
    </>
  );
};

export default CustomDropDown;
