import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import TopTitle from "../../Components/TopTitle";
import PathList from "../../Common/PathList";
import DUMMY_USER from "../../Assets/loginimages/logo.png";
import { toast } from "react-toastify";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import moment from "moment";
import TableContainer from "../../Components/TableContainer";
import dashboard1 from "../../Assets/DashboardImages/dashboard1.png";
import dashboard2 from "../../Assets/DashboardImages/dashboard2.png";
import dashboard3 from "../../Assets/DashboardImages/dashboard3.png";
import dashboard4 from "../../Assets/DashboardImages/dashboard4.png";
import dashboard5 from "../../Assets/DashboardImages/dashboard5.png";
import crossIcon from "../../Assets/common/crossIcon.png";
import CorrectIcon from "../../Assets/common/Correcticon.png";
import Loader from "../../Components/Loader";
import CustomAction from "../../Components/CustomAction";
import Swal from "sweetalert2";
import ViewBusinessDetailModal from "../BusinessManagement/ViewBusinessDetailModal";

const Dashboard = () => {
  const [openBusinessDetailsModal, setOpenBusinessDetailsModal] =
    useState(false);

  const [businessDetails, setBusinessDetails] = useState({});
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [getBusiness, setGetBusiness] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString(); // Less than 1K
    }
  }
  const getDashboardAnalytics = async () => {
    try {
      setDashboardLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_ADMIN_DASHBOARD);
      if (res && res?.data?.s === 1) {
        setDashboardDetails(res?.data?.r);
      }
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };
  const dashboardTopDetails = [
    {
      id: 1,
      img: dashboard1,
      mainTitle: formatNumber(`${dashboardDetails?.total_users}`),
      subTitle: "Total Users",
    },
    {
      id: 2,
      img: dashboard2,
      mainTitle: formatNumber(`${dashboardDetails?.premium_users}`),
      subTitle: "Premium Users",
    },
    {
      id: 3,
      img: dashboard3,
      mainTitle: formatNumber(`${dashboardDetails?.free_users}`),
      subTitle: "Free Users",
    },
    {
      id: 4,
      img: dashboard4,
      mainTitle: formatNumber(`${dashboardDetails?.total_posts}`),
      subTitle: "Total Posts",
    },
    {
      id: 5,
      img: dashboard5,
      mainTitle: formatNumber(`${dashboardDetails?.total_jokes}`),
      subTitle: "Total Jokes",
    },
  ];
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          user_id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(ApiList.BLOCK_USER, params);
          if (response?.data?.s === 1) {
            setUserList((prevData) =>
              prevData.filter((feed) => feed.id !== id)
            );
            toast.success("user Deleted Sucessfully !.");
            getUserList();
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete user. Please try again.");
        }
      }
    });
  };
  const handleBlockUnBlock = async (userId, isStatus) => {
    const newStatus = isStatus === 1 ? -1 : 1;
    Swal.fire({
      title: "Are you sure?",
      text:
        newStatus === -1
          ? "You want to block this user"
          : "You want to unblock this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("user_id", userId);
          formData.append("status", newStatus);
          const res = await AxiosInstance.post(ApiList.BLOCK_USER, formData);
          if (res?.data?.s) {
            toast.success(
              `User ${
                newStatus === -1 ? "blocked" : "unblocked"
              } Sucessfully !.`
            );
            getUserList();
          }
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const columns1 = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_img",
      headerName: "Profile Photo",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={
              params?.row?.profile_img
                ? `https://api.superkidz.online${params?.row?.profile_img}`
                : DUMMY_USER
            }
            alt="Profile"
            style={{ width: "30px", height: "30px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      minWidth: 150,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "premium",
      headerName: "Premium",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            bgcolor: "#3BC6F1",
            borderRadius: "12px",
          }}
        >
          <Typography
            sx={{ px: 3, py: 1, fontSize: "16px", fontWeight: "500" }}
          >
            Premium
          </Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const { status, id } = params?.row;
        console.log("id", id);

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {}
            <CustomAction
              showBlock={status === 1}
              showUnBlock={status === -1}
              showDelete={true}
              isBlock={handleBlockUnBlock}
              isUnBlock={handleBlockUnBlock}
              isDelete={() => handleDelete(params?.row?.id)}
              userId={id}
              isStatus={status}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getUserList();
    getDashboardAnalytics();
  }, [selectedCategory]);
  const getUserList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_USER_LIST, {
        params: {
          status: selectedCategory,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
          dob: moment(user?.dob).format("DD/MM/YYYY"),
        }));
        setUserList(formattedUsers);
      } else {
        setUserList([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  const columns = [
    { field: "rowid", headerName: "Sr.No", width: 100 },
    {
      field: "thumb",
      width: 200,
      headerName: "Profile Photo",
      renderCell: (params) => {
        const { business_media } = params?.row;

        return (
          <img
            src={
              business_media?.length > 0 && business_media[0]?.thumb
                ? `https://api.superkidz.online${business_media[0]?.thumb}`
                : DUMMY_USER
            }
            alt="Profile"
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        );
      },
    },
    {
      field: "phno_cc",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{`${e?.row?.phno_cc} ${e?.row?.phno}`}</Typography>
          </Box>
        );
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "business_name",
      headerName: "Business Name",
      flex: 1,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      minWidth: 200,
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "approve_status",
      headerName: "Status",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          // opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };
        if (e?.row?.approve_status === 1) {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  background: "green",
                  color: "white",
                }}
              >
                Approved
              </span>
            </Box>
          );
        } else if (e?.row?.approve_status === 0) {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                // gap: "1.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  // gap: "1.5rem",
                }}
              >
                <IconButton
                  onClick={() => handleAcceptReject(e?.row?.id, 1)}
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                >
                  <img
                    src={CorrectIcon}
                    alt="user"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => handleAcceptReject(e?.row?.id, -1)}
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                >
                  <img
                    src={crossIcon}
                    alt="user"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </IconButton>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  background: "#D00C00",
                  color: "white",
                }}
              >
                Rejected
              </span>
            </Box>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      renderCell: (params) => (
        <Box
          onClick={() => handleView(params?.row)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <CustomAction showView={true} />
        </Box>
      ),
    },
  ];
  const handleAcceptReject = async (id, value) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        value === -1
          ? "You want to reject this business"
          : "You want to approve this business",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("approve_status", value);
          formData.append("id", id);
          const sendData = await AxiosInstance.post(
            ApiList.UPDATE_BUSINESS,
            formData
          );
          if (sendData?.data?.s) {
            getBusinessList();
            toast.success(sendData?.data?.m);
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    });
  };
  const handleView = (elm) => {
    setOpenBusinessDetailsModal(true);
    setBusinessDetails(elm);
  };
  const getBusinessList = async () => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_BUSINESS_LIST);
      if (res && res?.data?.s === 1) {
        const formattedUsers = res?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
        }));
        setGetBusiness(formattedUsers);
      } else {
        setGetBusiness([]);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error(error);
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    getBusinessList();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          p: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <TopTitle title={"Dashboard"} />
        </Box>
        {dashboardLoading ? (
          <Loader sx={{height:"200px"}} />
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              py: 3,
            }}
          >
            {dashboardTopDetails.map((x) => {
              return (
                <Box
                  key={x.id}
                  sx={{
                    width: "250px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "20px",
                    borderRadius: "15px",
                    p: 3,
                    background: "#EFFAFF",
                  }}
                >
                  <Box>
                    <img src={x.img} alt="" />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                      {x.mainTitle}
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      {x.subTitle}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            gap: "50px",
            pb: 5,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "20px" }}>Recent Users</Typography>
              <Link sx={{ cursor: "pointer" }} to={PathList.UserManagement}>
                <Typography sx={{ fontSize: "20px" }}>View All</Typography>
              </Link>
            </Box>{" "}
            <Box
              sx={{
                mt: 3,
              }}
            >
              <TableContainer
                sx={{ height: { xs: "300px", md: "300px", xl: "400px" } }}
                rows={userList}
                columns={columns1}
                loading={isLoading}
                hideFooter={true}
                pagination={false}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "20px" }}>Recent Business</Typography>
              <Link sx={{ cursor: "pointer" }} to={PathList.BusinessManagement}>
                <Typography sx={{ fontSize: "20px" }}>View All</Typography>
              </Link>
            </Box>{" "}
            <Box sx={{ mt: 3 }}>
              <TableContainer
                sx={{ height: { xs: "300px", md: "300px", xl: "400px" } }}
                rows={getBusiness}
                columns={columns}
                loading={isLoading}
                hideFooter={true}
                pagination={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ViewBusinessDetailModal
        open={openBusinessDetailsModal}
        onClose={() => setOpenBusinessDetailsModal(false)}
        close={() => setOpenBusinessDetailsModal(false)}
        businessDetails={businessDetails}
        handleAcceptReject={handleAcceptReject}
      />
    </>
  );
};

export default Dashboard;
