import { Box,  Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link,  useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../Components/CustomTextField";
import CustomLoader from "../Components/CustomLoader";
import logo from "../Assets/loginimages/logo.png";
import AxiosInstance from "../Config/AxiosInstance";
import { ApiList } from "../Config/ApiList";
import { toast } from "react-toastify";
import PathList from "../Common/PathList";
const LoginScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotpassword, setrForgotpassword] = useState(false);
  useEffect(() => {
    let userinfo
    try {
      userinfo = JSON.parse(localStorage.getItem("SUPER_COOL"))
    } catch (error) {
      userinfo = null
    }
    if (userinfo) {
      return navigate(PathList.Dashboard)
    }
  }, [])
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required!"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const params = {
          email: values.email,
          password: values.password,
        };
        const response = await AxiosInstance.post(ApiList.LOGIN, params);
        if (response && response?.data?.s === 1) {
          if (response?.data?.r?.role === 2) {
            localStorage.setItem("SUPER_COOL", JSON.stringify(response?.data?.r));
            navigate("/dashboard");
            toast.success("Login Sucessfully.");
            resetForm();
          } else {
            toast.error("You Are Not Authenticated");
          }
        } else {
          toast.error(response.data.m);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const ForgotPasswordValidation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const params = {
          email: values.email,
          role: 2
        };
        const response = await AxiosInstance.post(ApiList.FORGOT_PASSWORD, params);
        if (response && response?.data?.s === 1) {
          toast.success(response.data.m);
          resetForm();
        } else {
          toast.error(response.data.m);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          backgroundImage: "url(/login.png  )",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  mb: -5,
                  display: {xs:"none",sm:"none",md:"none",lg: "none", xl:'flex'},
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                bgcolor: "white",
                borderRadius: "30px",
                py: 1,
                position: "relative",
                zIndex: "2",
              }}
            >
              {forgotpassword ? (
                <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                  <Typography sx={{ fontSize: "25px", fontWeight: "500" }}>
                    Forget Password?
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#000000B2" }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </Typography>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      ForgotPasswordValidation.handleSubmit();
                      return false;
                    }}
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1.5rem",
                      paddingTop: "30px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <CustomTextField
                        label="Email Address"
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={ForgotPasswordValidation.values.email}
                        onBlur={ForgotPasswordValidation.handleBlur}
                        onKeyUp={ForgotPasswordValidation.handleBlur}
                        errors={ForgotPasswordValidation.touched.email && ForgotPasswordValidation.errors.email}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          ForgotPasswordValidation.handleChange(e);
                        }}
                      // isLoading={isLoading}
                      />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", gap: "20px" }}>
                      <CustomLoader
                        btnTitle="Back"
                        type="button"
                        onSubmit={() => setrForgotpassword(false)}
                      />
                      <CustomLoader
                        btnTitle="Submit"
                        loading={isLoading}
                        type="submit"
                      />
                    </Box>
                  </form>
                </Box>
              ) : (
                <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                  <Typography sx={{ fontSize: "35px", fontWeight: "600" }}>
                    Welcome Back!
                  </Typography>
                  <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  Sign In to Your Admin Dashboard
                  </Typography>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }}
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1rem",
                      paddingTop: "30px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <CustomTextField
                        label="Email Address"
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={formik.touched.email && formik.errors.email}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      // isLoading={isLoading}
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <CustomTextField
                        label="Password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        errors={
                          formik.touched.password && formik.errors.password
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      // isLoading={isLoading}
                      />
                    </Box>
                    <Link
                      onClick={() => setrForgotpassword(true)}
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontSize: "16px",
                        color: "black",
                        textDecoration: "none",
                        fontWeight: "500",
                      }}
                    >
                      Forgot Password?
                    </Link>
                    <Box
                      sx={{
                        width: "55%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader
                        btnTitle="Submit"
                        loading={isLoading}
                        type="submit"
                      />
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginScreen;
