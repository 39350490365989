import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FeedSwiper from "../../Components/FeedSwiper";
const MediaDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const media = location?.state?.mediaArray;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            p: 2,
            position: "sticky",
            top: "0px",
            left: "0px",
            bgcolor: "white",
            zIndex: "2",
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <IconButton onClick={() => navigate("/reportfeed")} sx={{ p: 0 }}>
            <ChevronLeftIcon sx={{ color: "black", fontSize: "36px" }} />
          </IconButton>
          <Typography
            sx={{ fontSize: { xs: "24px", xl: "35px" }, fontWeight: "600" }}
          >
            Media Details
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 68px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "500px",
            }}
          >
            <FeedSwiper media={media} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MediaDetails;
