import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PathList from "./PathList";
import LoginScreen from "../Screens/LoginScreen";
import Layout from "../Screens/Layout/Layout";
import Dashboard from "../Screens/Dashboard/Dashboard";
import UserManagement from "../Screens/UserManagement/UserManagement";
import BusinessManagement from "../Screens/BusinessManagement/BusinessManagement";
import CategoryManagement from "../Screens/CategoryManagement/CategoryManagement";
import ContectUsFormManagement from "../Screens/ContectUsFormManagement/ContectUsFormManagement";
import ContentManagement from "../Screens/ContentManagement/ContentManagement";

import ReportFeedManagement from "../Screens/ReportFeedManagement/ReportFeedManagement";
import ReportUserList from "../Screens/ReportFeedManagement/ReportUserList";
import MediaDetails from "../Screens/ReportFeedManagement/MediaDetails";
import ReportedJokesDetails from "../Screens/ReportFeedManagement/ReportedJokesDetails";
import { Middleware } from "../Config/MiddleWare";


const RouteList = () => {
  return (
    <Routes>
      <Route path={PathList.Login} element={<LoginScreen />} />
      <Route
        path={PathList.Dashboard}
        element={<Middleware children={<Layout element={<Dashboard />} />} />}
      />
      <Route
        path={PathList.UserManagement}
        element={
          <Middleware children={<Layout element={<UserManagement />} />} />
        }
      />
      <Route
        path={PathList.CategoryManagement}
        element={
          <Middleware children={<Layout element={<CategoryManagement />} />} />
        }
      />
      <Route
        path={PathList.BusinessManagement}
        element={
          <Middleware children={<Layout element={<BusinessManagement />} />} />
        }
      />
      <Route
        path={PathList.ContentManagement}
        element={
          <Middleware children={<Layout element={<ContentManagement />} />} />
        }
      />
      <Route
        path={PathList.ContectUsFormManagement}
        element={
          <Middleware
            children={<Layout element={<ContectUsFormManagement />} />}
          />
        }
      />
      <Route
        path={PathList.ReportFeedManagement}
        element={
          <Middleware
            children={<Layout element={<ReportFeedManagement />} />}
          />
        }
      />
      <Route
        path={PathList.ReportUserList}
        element={
          <Middleware
            children={<Layout element={<ReportUserList />} />}
          />
        }
      />
      <Route
        path={PathList.MediaDetails}
        element={
          <Middleware
            children={<Layout element={<MediaDetails />} />}
          />
        }
      />
      <Route
        path={PathList.ReportedJokesDetails}
        element={
          <Middleware
            children={<Layout element={<ReportedJokesDetails />} />}
          />
        }
      />
    </Routes>
  );
};

export default RouteList;
