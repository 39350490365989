import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import CustomLoader from "../../Components/CustomLoader";
import EditIcon from "../../Assets/loginimages/edit.png";
import "react-quill/dist/quill.snow.css";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import { toast } from "react-toastify";
const AboutUs = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setEditMode] = useState(false);
  const getAboutUs = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.GET_ABOUT_US);
      if (response && response?.data?.s === 1) {
        formik.setFieldValue("aboutUs", response?.data?.r?.content);
        setEditMode(true);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch privacy policy. Please try again.");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aboutUs: "",
    },
    validationSchema: Yup.object({
      aboutUs: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();
          formData.append("content", values.aboutUs);
          const res = await AxiosInstance.post(
            ApiList.UPDATE_ABOUT_US,
            formData
          );
          if (res && res?.data?.s) {
            toast.success(res.data.m);
            resetForm();
            getAboutUs();
          }
        } catch (error) {
          console.log("error", error);
          toast.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  return (
    <Box
      sx={{
        width: "100%",
        pb: 5,
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
        style={{
          position: "relative",
          backgroundColor: "#fff",
          padding: "40px 25px 10px 25px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          borderRadius: "10px",
        }}
      >
        {isEditMode && (
          <IconButton
            type="button"
            sx={{
              position: "absolute",
              top: "5px",
              right: "10px",
              width: "30px",
              height: "30px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              cursor: "pointer",
            }}
            onClick={() => setEditMode(false)}
          >
            <img src={EditIcon} alt="editIcon" />
          </IconButton>
        )}
        {isLoading ? (
          <Skeleton width={"100%"} height={"230px"} />
        ) : (
          <ReactQuill
            placeholder="Description"
            value={formik.values.aboutUs}
            ref={ref}
            readOnly={isEditMode}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                  { align: "right" },
                  { align: "center" },
                  { align: null },
                  { align: "justify" },
                ],
              ],
            }}
            onChange={(content, delta, source, editor) => {
              content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
              formik.setFieldValue("aboutUs", content);
            }}
            className="termsCondtionContent"
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "24px",
          }}
        >
          <CustomLoader
            type="submit"
            variant="contained"
            fullWidth
            disabled={
              !formik?.values?.aboutUs?.replace(/<[^>]+>/g, "").trim() ||
              isEditMode
            }
            btnTitle="Save"
            loading={isLoading}
            sx={{
              width: "120px",
              height: "48px",
            }}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AboutUs;
