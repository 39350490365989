import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { NavMenuList } from "./MenuList";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, ListItemIcon, Typography } from "@mui/material";
import Swal from "sweetalert2";
import LOGO from "../../Assets/SidebarImage/sidebarlogo.png";
import LogoutIcon from "@mui/icons-material/Logout";
const drawerWidth = 280;

function Layout(props) {
  const { element } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Clear local storage
        localStorage.clear();

        // Show success message
        Swal.fire({
          title: "Logged Out",
          text: "You have been successfully logged out. Redirecting to login page...",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          // Navigate to login page
          navigate("/");
        });
      }
    });
  };
  //  const handleAuth = (element) => {
  //   // console.log(element)
  //   let userinfo
  //   try {
  //     userinfo = JSON.parse(localStorage.getItem("SUPER_COOL"))
  //   } catch (error) {
  //     userinfo = null
  //   }
  //   // console.log(userinfo)
  //   if (userinfo?.role === 2) {
  //    return element
  //   }else{
  //     // return navigate("/")
  //     return window.location.pathname = "/"
  //   }
  //  }
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box
      sx={{
        bgcolor: "#E8F9FE",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 5,
        }}
      >
        <img
          src={LOGO}
          alt="logo"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{ width: "100%", height: "calc(100vh - 150px)", overflowY: "auto" }}
        className="customSidebarList"
      >
        <List>
          {NavMenuList.map(({ title, path, icon, active }, i) => (
            <ListItem key={i} disablePadding sx={{ m: "0rem 0" }}>
              <Link
                style={{
                  color: "black",
                  textDecoration: "none",
                  width: "100%",
                }}
                to={path}
              >
                <ListItemButton
                  sx={{
                    background: location.pathname.includes(path)
                      ? "#3BC6F1"
                      : "transparent",
                    borderRadius: location.pathname.includes(path)
                      ? "10px"
                      : "0px",
                    p: 0.5,
                    mx: 0.5,
                    my: 0.2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "44px",
                    }}
                  >
                    {location.pathname.includes(path) ? (
                      <img
                        src={active}
                        style={{
                          boxSizing: "border-box",
                          height: "30px",
                          width: "30px",
                        }}
                        alt=""
                        srcSet=""
                      />
                    ) : (
                      <img
                        src={icon}
                        style={{
                          boxSizing: "border-box",
                          height: "30px",
                          width: "30px",
                        }}
                        alt=""
                        srcSet=""
                      />
                    )}{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    sx={{
                      color: location.pathname.includes(path)
                        ? "black"
                        : "#737373",
                      ".MuiTypography-root": {
                        fontWeight: "500",
                        fontSize: "16px",
                      },
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* Logout Button */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
          height: "50px",
          boxSizing: "border-box",
        }}
      >
        <Button
          type="button"
          sx={{
            width: "100%",
            background: "transparent",
            padding: "8px 12px",
            fontWeight: "500",
            fontSize: "18px",
            color: "#737373",
            textTransform: "capitalize",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            borderRadius: "0px",
            "&:hover": {
              background: "transparent",
              color: "#737373",
            },
          }}
          onClick={() => handleLogout()}
        >
          <LogoutIcon />
          <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
            Logout{" "}
          </Typography>
        </Button>
      </Box>

      <Divider />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
          // ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ display: { sm: "none" } }}>
          <Toolbar />
        </Box>
        <Box>{element}</Box>
      </Box>
    </Box>
  );
}

export default Layout;
